function getAccessToken() {
    var access_token = null;

    if (localStorage.getItem("access_token")) {
        access_token = localStorage.getItem("access_token");
    }

    return access_token;
}

function authHeader() {
    var accessToken = getAccessToken();
    return { Authorization: 'Bearer ' + accessToken };
}

function storeAccessToken(token) {
    localStorage.setItem('access_token', token);
}

function removeAccessToken() {
    localStorage.removeItem('access_token');
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}

function jwtString(jwtToken) {
    const access_token = getAccessToken();
    if (access_token) {
        return parseJwt(access_token);
    }

    return false
}

function tokenExpired() {
    var jwt = jwtString();
    if (jwt) {
        var now = new Date().getTime() / 1000;
        return (now > jwt.exp);
    }

    return true;
}

export {
    authHeader,
    storeAccessToken,
    removeAccessToken,
    jwtString,
    tokenExpired,
};